<template>
  <div>
  <div class="hidden md:inline-block w-full text-center">
      <div class="fixed w-full z-10 text-center">
        <img class="w-full lg:mt-24 inline sm:hidden" src="@/assets/banner.jpg" />
        <img class="w-full sm:inline hidden" src="@/assets/DesktopBanner.png" />
      </div>
      <div class="pt-[8%]">
    </div>

    <div class="relative w-full text-center pt-4 xl:w-5/6 xl:inline-block">
      <button v-if="showBack" class="hidden print:!hidden md:inline absolute left-0 text-black bg-caspyellow border-4 border-gray-500 rounded-lg w-32 py-2 font-bold" @click="back()">
        Zurück
      </button>
      <span class="font-bold text-4xl w-8/12">{{title}}</span><br />
    </div>
  </div>
  <div class="md:hidden w-full fixed h-10 text-center p-1 shadow-sm border-b bg-gray-100 border-b-gray-300 z-20">
    <div v-if="showBack" class="absolute cursor-pointer" @click="back()">
      <i class="fa fa-chevron-left fa-lg align-middle text-iosblue" /><span class="text-xl text-iosblue align-middle ml-2 -mt-1 inline-block">Zurück</span>
    </div>
    <div v-show="showReload" class="absolute right-3 cursor-pointer" @click="$emit('reload')">
      <i class="fa fa-rotate-right fa-lg align-middle text-iosblue" />
    </div>
    <span class="font-bold text-xl">{{ title }}</span>
  </div>
  <div class="w-full h-10"></div>
</div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    title: String,
    custom: {
      type: Boolean,
      default: false
    },
    backUrl: {
      type: String,
      default: "..."
    },
    showBack: {
      type: Boolean,
      default: true
    },
    showReload: Boolean
  },
  methods: {
    back() {
      if(this.custom) {
        this.$emit("custom");
      }
      else if(this.backUrl === "...") {
        window.history.back();
      }else{
        window.location = this.backUrl;
      }
    }
  },
  emits: ["reload"]
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
