import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'

router.afterEach((to) => {
  const newUrl = `${window.location.origin}${to.fullPath}`;
  if (window.Android && window.Android.onUrlChanged) {
    window.Android.onUrlChanged(newUrl);
  } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.urlChanged) {
    window.webkit.messageHandlers.urlChanged.postMessage(newUrl);
  }
});

const app=createApp(App)
app.use(router)
app.mount('#app')

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      registration.onupdatefound = () => {
        const newWorker = registration.installing;
  
        newWorker.onstatechange = () => {
          if (newWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // New update available, notify user
                //window.location.reload();
            }
          }
        };
      };
    });
  }
  
