<template>
  <div class="w-full h-full relative text-center m-0 p-0">
    <Toolbar :showBack="authenticated" :title="authenticated?'Web-Login':'Login'"></Toolbar>
    <div v-if="authenticated" class="relative w-full text-center xl:w-1/2 xl:inline-block">
      <div class="text-left border-8 border-gray-500 rounded-xl shadow-lg mt-5 p-5 w-11/12 sm:w-5/6 md:w-1/2 inline-block">
        <span class="block text-2xl font-bold">Login-Code generieren</span>
        <span>Gebe in einem Browser auf einem PC folgende Adresse oben in die Adressleiste ein:<br><b>casp-web.trit-solutions.com/login</b><br>Dort kannst du dich mit dem Code einloggen, der erscheint, sobald du auf den Button klickst.</span>
        <div class="w-full text-center">
          <span v-if="code" class="bg-gray-200 font-bold text-4xl tracking-widest px-2 mt-3 inline-block">{{ code }}</span><br>
          <button class="inline-block font-bold mt-4 bg-gray-500 rounded p-2 text-white" @click="generateLoginCode()">
            Neuen Code generieren
          </button>
        </div>
      </div>
    </div>
    <div v-else class="relative w-full text-center xl:w-2/3 xl:inline-block">
      <div class="w-full md:grid md:grid-cols-2">
        <div class="px-5">
          <div class="border-8 border-gray-500 rounded-xl shadow-lg mt-5 p-5 w-full inline-block">
            <span class="block text-3xl font-bold">Intern</span>
            <div class="w-full text-center">
              <input v-model="loginCode" @input="checkLoginCode()" class="bg-gray-200 font-bold text-center text-3xl tracking-widest px-2 mt-3 inline-block w-full sm:w-1/2" placeholder="Login-Code" />
            </div>
            <span v-if="loginError" class="text-center block mx-5 mt-3 py-1 px-3 font-bold text-red-500 border-4 border-red-500">{{ loginError }}</span>
          </div>
        </div>
        <div class="px-5">
          <div class="border-8 border-gray-500 rounded-xl shadow-lg mt-5 p-5 w-full inline-block text-center">
            <span class="block text-3xl font-bold">Kunde</span>
            <div class="inline-block">
              <div class="text-left w-auto">
                <label class="font-bold block">E-Mail Adresse:</label>
                <input v-model="loginMail" class="bg-gray-200 font-bold text-lg px-2 inline-block w-full" />
              </div>
              <div v-if="state == 'mailCode'" class="inline-block w-full sm:w-1/2 text-center mt-3">
                <input v-model="loginMailCode" @input="checkMailCode()" class="bg-gray-200 font-bold text-center text-3xl tracking-widest px-2 mt-3 inline-block w-full" placeholder="Login-Code" />
              </div>
              <span v-if="mailLoginError" class="text-center block mx-5 mt-3 py-1 px-3 font-bold text-red-500 border-4 border-red-500">{{ mailLoginError }}</span>
              <span v-else-if="mailLoginSuccess" class="text-center block mx-5 mt-3 py-1 px-3 font-bold text-green-500 border-4 border-green-500">{{ mailLoginSuccess }}</span>
              <div v-else class="pt-3 w-full text-right">
                <button v-if="state != 'mailCode'" @click="requestMailCode()">Login-Code anfordern</button>
                <div v-else>
                <button @click="requestMailCode()">Code erneut anfordern</button>
                <button @click="checkMailCode()">Prüfen</button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CASP from "../CASP";
import Toolbar from "@/components/Toolbar.vue";
import { useCookies } from "vue3-cookies";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      code: "",
      authenticated: false,
      loginCode: "",
      loginError: "",
      mailLoginError: "",
      mailLoginSuccess: "",
      loginMail: "",
      loginMailCode: "",
      state: "",
    };
  },
  methods: {
    checkLoginCode() {
      if (this.loginCode.length == 6) {
        CASP.checkLoginCode(this.loginCode, (res) => {
          if (res) {
            this.cookies.set("authtoken", res.token, "1y")
            window.location = "/";
          }
          else {
            this.loginError = "Ungültiger oder abgelaufener Code"
            this.loginCode = ""
          }
        })
      }
    },
    checkMailCode() {
      if (this.loginMailCode.length == 6) {
        CASP.checkMailCode(this.loginMailCode, (status, res) => {
          if (res) {
            window.location = "/admin/external";
          }
          else {
            this.mailLoginError = "Ungültiger oder abgelaufener Code"
            this.mailLoginCode = ""
          }
        })
      }
    },
    requestMailCode() {
      CASP.requestMailCode(this.loginMail, (status, res) => {
        if (status == 200) {
          this.state = "mailCode"
          this.mailLoginSuccess = "Code verschickt"
          setTimeout(() => {
            this.mailLoginSuccess = ""
          }, 2000)
        } else if (status == 404) {
          this.mailLoginError = "Mailadresse unbekannt"
          this.mailLoginCode = ""
          setTimeout(() => {
            this.mailLoginError = ""
          }, 2000)
        }
      })
    },
    generateLoginCode() {
      let vm = this;
      CASP.generateLoginCode(this.token, (res) => {
        if (res) {
          vm.code = res.code
        }
      })
    }
  },
  components: { Toolbar },
  created: function () {
    document.getElementById("mainspinner").style.display = "none";
    CASP.getStats(this.cookies.get("authtoken"), (status, response) => {
      if (status == 200) {
        this.authenticated = true
        if (JSON.parse(response).type != 'internal') {
          window.location = '/admin'
        }
      }
    })
  },
};
</script>