<template>
  <Toolbar title="Externer Adminbereich" />
  <div
    v-if="selectedJob != null"
    class="fixed w-full h-full top-0 left-0 bg-black/50 text-center z-40"
  >
    <div
      class="mt-10 inline-block w-full md:w-auto bg-white rounded-lg p-4 relative"
    >
      <div
        style="position: absolute; right: 40px; color: black"
        class="cursor-pointer"
        @click="selectedJob = null"
      >
        <i ref="xmark" class="fa fa-xmark text-4xl"></i>
      </div>
      <span class="text-2xl font-bold">Dokumente</span>
      <table class="max-h-96 overflow-y-auto">
        <thead>
          <tr>
            <th>Typ</th>
            <th class="text-center">Aktionen</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="document in selectedJob.documents" :key="document.id">
            <td>{{ document.type }}</td>
            <td><button v-if="document.signatureMissing" class="bg-red-500" @click="showDocument(document)">Unterschreiben</button><button v-else @click="showDocument(document)">Ansehen</button></td>
          </tr>
          <tr
            v-if="selectedJob.documents.length == 0"
            class="col-span-6 text-center"
          >
            <td colspan="6">Keine Dokumente gefunden</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div>
    <span class="font-bold text-xl">Aufträge mit Dokumenten</span>
    <table>
      <thead>
        <tr>
          <th>Vertrag</th>
          <th>Standort</th>
          <th>Einsatzstelle</th>
          <th>Datum</th>
          <th>Uhrzeit</th>
          <th class="text-center">Aktionen</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="job in jobs" :key="job.id">
          <td>{{ job.Vertragsbeschreibung }}</td>
          <td>{{ job.Einsatzort_Beschreibung }}</td>
          <td>{{ job.Einsatzstelle_Beschreibung }}</td>
          <td>{{ job.Datum_von }}</td>
          <td>{{ job.Uhrzeit }}</td>

          <td>
            <button v-if="!job.signatureMissing"
              class="bg-gray-500 text-white py-2 px-4 rounded-lg font-bold"
              @click="selectedJob = job"
            >
              Dokumente anzeigen
            </button>
            <button v-else
              class="bg-red-500 text-white py-2 px-4 rounded-lg font-bold"
              @click="selectedJob = job"
            >
              Dokumente unterschreiben
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import CASP from "@/CASP.js";
import Toolbar from "@/components/Toolbar.vue";

export default {
  name: "AdminDocuments",
  data() {
    return {
      jobs: [],
      selectedJob: null,
    };
  },

  components: { Toolbar },

  methods: {
    showDocument(document) {
      if(document.type=="Rundgang"){
        window.open(process.env.VUE_APP_API_URL + "/routes/jobs/" + document.job + "/download");
      }  else{
        window.open("/jobs/" + document.job + "/documents/" + document.id+"?close=true");
      }    
    },
    getJobsForExternalUsers() {
      CASP.getJobsForExternalUsers((status, res) => {
        if (status == 200) {
          this.jobs = res;
        }
      });
    },
  },
  created: function () {
    this.getJobsForExternalUsers();
    document.getElementById("mainspinner").style.display = "none";
  },
};
</script>