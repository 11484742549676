<template>
  <toolbar
    title="Kontrollgang"
    :custom="true"
    @custom="changeUrl({
        name: 'EWKSList',
        params: { jobid: this.$route.params.jobid },
      })
    "
  />
  <div
    v-if="scanError"
    class="fixed top-10 center p-10 bg-red-500 rounded-lg text-center text-3xl font-bold text-black"
  >
    {{ scanError }}
  </div>
  <div
    v-if="scanSuccess"
    class="fixed top-10 center p-10 bg-green-500 rounded-lg text-center text-3xl font-bold text-black"
  >
    {{ scanSuccess }}
  </div>

  <div v-show="qrscan" class="absolute w-full h-full top-0 left-0 z-10">
    <div class="text-center pt-36 w-full h-full z-10 bg-black/80">
      <video
        id="video"
        ref="video"
        class="w-96 h-96 max-h-96 inline-block"
        autoplay
      ></video
      ><br />
      <button class="text-black bg-red-500" @click="stopQRScan()">
        Abbrechen
      </button>
    </div>
  </div>
  <div v-show="camera" class="absolute w-full h-full top-0 left-0 z-10">
    <div class="text-center pt-14 w-full h-full z-10 bg-black/80">
      <video
        id="camera"
        ref="camera"
        class="inline-block"
        autoplay
        playsInline
        muted
        disablePictureInPicture
      ></video
      ><br />
      <button class="text-black bg-green-500" @click="takePhoto()">
        Foto aufnehmen</button
      ><br /><button
        class="text-black bg-red-500 mt-10"
        @click="cancelCamera()"
      >
        Abbrechen
      </button>
    </div>
  </div>
  <div v-if="stats.currentJob" class="md:p-4">
    <div class="bg-white rounded-lg p-4">
      <span class="text-2xl font-bold inline-block mr-4"
        >Route: {{ route.name }}</span
      >
      <div class="grid grid-cols-2 font-bold mt-4">
        <div>
          <label class="mr-4">Einsatzort:</label>
          <input
            type="text"
            v-model="stats.currentJob.Einsatzort_Beschreibung"
            disabled
          />
        </div>
        <div>
          <label class="mr-4">Einsatzstelle:</label>
          <input
            type="text"
            v-model="stats.currentJob.Einsatzstelle_Beschreibung"
            disabled
          />
        </div>
        <div>
          <label class="mr-4">Mitarbeiter:</label>
          <input
            type="text"
            v-model="stats.currentJob.Mitarbeitername"
            disabled
          />
        </div>
        <div>
          <label class="mr-4">Datum:</label>
          <input type="date" v-model="tour.date" disabled />
        </div>
        <div>
          <label class="mr-4">Start:</label>
          <input type="time" v-model="tour.startedAt" disabled />
        </div>
        <div>
          <label class="mr-4">Ende</label>
          <input type="time" v-model="tour.finishedAt" disabled />
        </div>
      </div>
      <div v-if="!tour.startedAt" class="mt-4">
        <button @click="startTour()">Rundgang starten</button>
      </div>
    </div>
    <div v-if="tour.startedAt" class="p-2">
      <span class="text-xl font-bold">Wegpunkt erfassen</span>
      <div class="grid grid-cols-1 mt-2">
        <div class="text-center">
          <button class="inline-block" @click="startQRScan()">
            <i class="fas fa-qrcode fa-2x" /><span
              class="align-top mt-1 inline-block ml-2"
              >QR-Code</span
            >
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="tour.startedAt && route.waypoints"
      class="w-full overflow-x-auto p-2"
    >
      <div
        v-for="waypoint in route.waypoints"
        :key="waypoint"
        class="border-b border-black pb-2"
      >
        <span class="font-bold text-xl">{{ waypoint.name }}</span
        ><br />
        <span><b>Code: </b>{{ waypoint.code }}</span
        ><br />
        <span><b>Status:</b></span>
        <div
          v-if="!waypoint.scanned"
          class="text-sm font-bold inline-block p-1 rounded ml-2 border text-red-800 bg-red-200 border-red-800"
        >
          Offen
        </div>
        <div
          v-else
          class="text-sm font-bold inline-block p-1 rounded ml-2 border text-green-800 bg-green-200 border-green-800"
        >
          Gescannt ({{ waypoint.scanned.scannedAt }})
        </div>

        <div v-if="waypoint.scanned">
          <b>Kommentar:</b><br />
          <textarea
            class="p-2 w-full"
            @input="editedComment = waypoint"
            v-model="waypoint.scanned.comment"
          ></textarea>
          <div class="text-right w-full">
            <img
              v-if="waypoint.scanned.picture"
              :src="waypoint.scanned.picture"
              class="w-20 inline-block"
            />
            <button
              v-if="!waypoint.scanned.picture"
              @click="openCamera(waypoint.scanned)"
              class="ml-2 rounded-lg align-top mt-1.5"
            >
              Foto aufnehmen
            </button>
            <button
              v-else
              class="ml-2 rounded-lg align-top mt-1.5"
              @click="removePhoto(waypoint.scanned)"
            >
              Foto löschen</button
            ><button
              v-if="editedComment == waypoint"
              @click="updateScan(waypoint.scanned)"
              class="ml-2 rounded-lg align-top mt-1"
            >
              Speichern
            </button>
          </div>
        </div>
        <div v-else-if="manuallyScanned != waypoint" class="w-full text-right">
          <button @click="manuallyScanned = waypoint">Manuell erfassen</button>
        </div>
        <div v-if="manuallyScanned == waypoint">
          <span class="text-red-500 font-bold"
            >Wieso kann der Wegpunkt nicht gescannt werden?</span
          >
          <textarea
            v-model="manualScanExplanation"
            rows="3"
            class="w-full border border-black p-2"
          ></textarea>
          <div class="w-full text-right">
            <button @click="manuallyScanned = null" class="mr-2">
              Abbrechen
            </button>
            <button
              v-if="manualScanExplanation.length > 0"
              @click="saveManualScanExplanation()"
            >
              Begründung speichern
            </button>
          </div>
        </div>
      </div>
      <div class="w-full text-center" v-if="!tour.finishedAt">
        <span
          v-if="route.waypoints.filter((w) => !w.scanned).length != 0"
          class="text-red-500 font-bold block"
          >Bitte alle Wegpunkte scannen!</span
        >
        <button v-else class="negative mt-2" @click="finishTour()">
          Tour abschließen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar.vue";
import CASP from "../../CASP";
export default {
  name: "Tour",
  components: {
    toolbar,
  },
  data() {
    return {
      manuallyScanned: null,
      manualScanExplanation: "",
      scanSuccess: false,
      camera: false,
      scanError: false,
      qrscan: false,
      window: window,
      tour: {
        waypointscans: [],
        date: new Date().toISOString().split("T")[0],
        job: this.$route.params.jobid,
        route: this.$route.params.routeid,
      },
      waypointscans: [],
      editedComment: false,
      currentWaypointscan: null,
      route: {},
      online: false,
      stats: {},
      local: {},
      stream: null,
      pingInterval: null
    };
  },
  methods: {
    openCamera(waypointscan) {
      this.currentWaypointscan = waypointscan;
      this.camera = true;
      navigator.mediaDevices
        .getUserMedia({
          video: { facingMode: { exact: "environment" } },
        })
        .then((stream) => {
          this.stream = stream;
          this.$refs.camera.srcObject = stream;
          this.$refs.camera.play();
        });
    },
    async cancelCamera() {
      this.camera = false;
      this.stream.getTracks().forEach((track) => track.stop());
    },
    takePhoto() {
      const canvas = document.createElement("canvas");
      canvas.width = this.$refs.camera.videoWidth;
      canvas.height = this.$refs.camera.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(this.$refs.camera, 0, 0, canvas.width, canvas.height);
      this.currentWaypointscan.picture = canvas.toDataURL("image/jpeg", 0.1);
      this.cancelCamera();
      this.updateScan(this.currentWaypointscan);
    },
    removePhoto(waypointscan) {
      waypointscan.picture = null;
      this.updateScan(waypointscan);
    },
    updateScan(waypointscan) {
      this.editedComment = false;
      if (this.online) {
        CASP.updateWaypointscan(
          waypointscan,
          (status, response) => {
            if (status == 200) {
              this.waypointscans[
                this.waypointscans.findIndex((t) => t.id == waypointscan.id)
              ] = response;
              this.tour.waypointscans[
                this.tour.waypointscans.findIndex(
                  (t) => t.id == waypointscan.id
                )
              ] = response;
              this.updateStats();
            } else  {
              this.saveLocally(waypointscan, "waypointscans");
            }
          }
        );
      } else {
        this.saveLocally(waypointscan, "waypointscans");
      }
    },
    startTour() {
      this.tour.index = this.$route.params.index;
      this.tour.job = this.$route.params.jobid;
      this.tour.route = this.$route.params.routeid;
      this.tour.startedAt = new Date().toLocaleTimeString();
      this.tour.status = "In Arbeit";

      if (this.online) {
        CASP.addTour(
          this.tour,
          (status, response) => {
            if (status == 200) {
              this.saveAndRedirect(response);
            } else  {
              this.saveLocally(this.tour, "tours");
            }
          }
        );
      } else {
        this.saveLocally(this.tour, "tours");
      }
    },
    changeUrl(to){
      clearInterval(this.pingInterval);
      this.$router.push(to);
    },
    updateTour() {
      if (this.online) {
        CASP.updateTour(
          this.tour,
          (status, response) => {
            if (status == 200) {
              this.updateStats();
              this.changeUrl("/job/" + this.$route.params.jobid + "/tours/");
            } else  {
              this.saveLocally(this.tour, "tours");
              this.changeUrl("/job/" + this.$route.params.jobid + "/tours/");
            }
          }
        );
      } else {
        this.saveLocally(this.tour, "tours");
        this.changeUrl("/job/" + this.$route.params.jobid + "/tours/");
      }
    },
    saveAndRedirect(tour) {
      this.tour = tour;
      this.updateStats();
    },
    stopQRScan() {
      if (this.qrscan) {
        window.rescanner.stopQRScan();
        this.qrscan = false;
      }
    },
    updateStats() {
      this.stats.currentJob.routes.find((r) => r.id == this.route.id).tours[
        this.$route.params.index - 1
      ] = this.tour;
      localStorage.setItem("stats", JSON.stringify(this.stats));
    },
    startQRScan() {
      if (this.qrscan) {
        window.rescanner.stopQRScan();
        this.qrscan = false;
        return;
      }
      console.log("start scan");
      window.rescanner.startQRScan(this.$refs.video, "environment");
      this.qrscan = true;
    },
    scanResult(message, scanType) {
      this.stopQRScan();
      console.log(message);
      // find waypoint where id is message
      let waypoint = this.route.waypoints.find((w) => w.code == message);
      if (waypoint) {
        this.saveWaypointscan(waypoint, scanType);
      } else {
        this.scanError = "Code gehört zu keinem Wegpunkt!";
        setTimeout(() => {
          this.scanError = false;
        }, 3000);
      }
    },
    saveManualScanExplanation() {
      this.saveWaypointscan(
        this.manuallyScanned,
        "MANUAL",
        this.manualScanExplanation
      );
      this.manuallyScanned = null;
      this.manualScanExplanation = "";
    },
    finishTour() {
      this.tour.status = "Beendet";
      this.tour.finishedAt = new Date().toLocaleTimeString();
      this.updateTour();
    },
    checkIfWaypointsAreScanned() {
      for (let i = 0; i < this.route.waypoints.length; i++) {
        this.route.waypoints[i].scanned = this.waypointscans
          .reverse()
          .find((w) => w.waypoint == this.route.waypoints[i].id);
      }
    },
    saveLocally(data, type) {
      if (!this.local[type]) this.local[type] = [];
      if (type == "waypointscans") {
        this.local[type] = this.local[type].filter(
          (x) => x.waypoint != data.waypoint
        );
      } else if (type == "tours") {
        this.local[type] = this.local[type].filter(
          (x) => !(x.route == data.route && x.index == data.index)
        );
      }
      this.local[type].push(data);
      localStorage.setItem("local", JSON.stringify(this.local));
    },
    async asyncAddOrUpdateTour(tour) {
      return new Promise((resolve) => {
        if (tour.id) {
          CASP.updateTour(
            tour,
            (status, response) => {
              resolve({ status, response });
            }
          );
        } else {
          CASP.addTour(tour, (status, response) => {
            resolve({ status, response });
          });
        }
      });
    },
    async asyncSaveWaypointscan(scan) {
      if (scan.id) {
        return new Promise((resolve) => {
          CASP.updateWaypointscan(scan, (status, response) => {
            resolve({ status, response });
          });
        });
      } else {
        return new Promise((resolve) => {
          CASP.addWaypointscan(
            scan,
            (status, response) => {
              resolve({ status, response });
            }
          );
        });
      }
    },
    async tryToSyncLocalUpdates() {
      let redirectAfter = false;
      if (!this.online) return;
      if (this.local.tours) {
        for (let i = 0; i < this.local.tours.length; i++) {
          let tour = this.local.tours[i];
          let { status, response } = await this.asyncAddOrUpdateTour(tour);
          if (status == 200) {
            console.log(response);  
            this.local.tours.splice(i, 1);
            i--;
            if (tour.route == this.route.id && tour.index == this.tour.index) {
              this.tour = response;
              if (this.tour.finishedAt) {
                redirectAfter = true;
              }
            }
            localStorage.setItem("local", JSON.stringify(this.local));
            this.updateStats();
          }
        }
      }
      if (this.local.waypointscans) {
        for (let i = 0; i < this.local.waypointscans.length; i++) {
          let waypoint = this.local.waypointscans[i];
          let { status, response } = await this.asyncSaveWaypointscan(waypoint);
          if (status == 200) {
            this.local.waypointscans.splice(i, 1);
            i--;
            this.tour.waypointscans.push(response);
            localStorage.setItem("local", JSON.stringify(this.local));
            this.updateStats();
          }
        }
      }
      if (redirectAfter) {
        this.changeUrl("/job/" + this.$route.params.jobid + "/tours/");
      }
    },
    saveWaypointscan(waypoint, scanType = "QR", comment = "") {
      if (waypoint.scanned) {
        this.scanError = "Wegpunkt bereits gescannt!";
        setTimeout(() => {
          this.scanError = false;
        }, 3000);
        return;
      }
      this.scanSuccess = "Wegpunkt erfolgreich gescannt!";
      setTimeout(() => {
        this.scanSuccess = false;
      }, 3000);
      waypoint.scanned = {
        jobid: this.$route.params.jobid,
        scannedAt: new Date().toLocaleTimeString(),
        waypoint: waypoint.id,
        route: this.$route.params.routeid,
        tourIndex: this.$route.params.index,
        scanType: scanType,
        comment: comment,
      };
      if (this.online) {
        CASP.addWaypointscan(
          waypoint.scanned,
          (status, response) => {
            if (status == 200) {
              waypoint.scanned = response;
              this.tour.waypointscans.push(response);
              console.log("adding waypoint to tour list after 200 of api");
              this.waypointscans.push(response);
              this.updateStats();
            } else {
              this.waypointscans.push(waypoint.scanned);
              this.saveLocally(waypoint.scanned, "waypointscans");
            }
          }
        );
      } else {
        this.waypointscans.push(waypoint.scanned);
        this.saveLocally(waypoint.scanned, "waypointscans");
      }
    },
  },
  created: function () {
    let vm = this;
    this.stats = JSON.parse(localStorage.getItem("stats"));
    this.local = localStorage.getItem("local")
      ? JSON.parse(localStorage.getItem("local"))
      : {};

    document.getElementById("mainspinner").style.display = "none";
    // Check online Status
    CASP.isOnline((online) => {
      this.online = online;
      if (online) {
        this.tryToSyncLocalUpdates();
      }
    });
    this.pingInterval=setInterval(() => {
      CASP.isOnline((online) => {
        if (this.online != online) {
          this.online = online;
          if (online) {
            this.tryToSyncLocalUpdates();
          }
        }
      });
    }, 10000);
    this.route = this.stats.currentJob.routes.find(
      (r) => r.id == this.$route.params.routeid
    );
    if(this.route.tours.find((t) => t.index == this.$route.params.index)){
      this.tour=this.route.tours.find((t) => t.index == this.$route.params.index);
    }

    // Try to retrieve latest tour details from localstorage
    if (
      this.local.tours &&
      this.local.tours.find(
        (t) =>
          t.route == this.$route.params.routeid &&
          t.index == this.$route.params.index &&
          t.job == this.$route.params.jobid
      )
    ) {
      this.tour = this.local.tours.find(
        (t) =>
          t.route == this.$route.params.routeid &&
          t.index == this.$route.params.index &&
          t.job == this.$route.params.jobid
      );
    }
    else{

    }
    if (this.local.waypointscans) {
      this.waypointscans = this.tour.waypointscans.concat(
        this.local.waypointscans.filter(
          (x) =>
            x.route == this.$route.params.routeid &&
            x.tourIndex == this.$route.params.index &&
            x.jobid == this.$route.params.jobid
        )
      );
    } else {
      this.waypointscans = JSON.parse(JSON.stringify(this.tour.waypointscans));
    }

    this.tour.date = this.tour.date.split("T")[0];
    this.checkIfWaypointsAreScanned();
    this.tryToSyncLocalUpdates();
    if (!window.rescanner) {
      let rescannerLib = document.createElement("script");
      rescannerLib.setAttribute("src", "/rescanner.js");
      rescannerLib.onload = () => {
        window.rescanner.onNFCSuccess = (message) => {
          vm.scanResult(message, "NFC");
        };
        window.rescanner.onQRSuccess = (message) => {
          vm.scanResult(message, "QR");
        };
        window.rescanner.startNFCScan();
      };
      document.head.appendChild(rescannerLib);
    } else {
      window.rescanner.onNFCSuccess = (message) => {
        vm.scanResult(message, "NFC");
      };
      window.rescanner.onQRSuccess = (message) => {
        console.log("scanresult called");
        vm.scanResult(message, "QR");
      };
      window.rescanner.startNFCScan();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
