<template>
  <div class="w-full h-full text-center">
    <toolbar :title="type" :showBack="!$route.query.close" />
    <Signature
      :docId="$route.params.doc"
      :type="currentSignature"
      v-show="sign"
      ref="sign"
      :path="currentPath"
      @close="sign = false"
      @documentSigned="documentSigned"
    />
    <div v-if="error">
      <span class="inline-block mt-10 font-bold">{{ error }}</span>
    </div>
    <div
      id="page"
      class="text-center w-full md:p-10 print:p-10"
      v-if="document"
    >
      <div
        v-if="stats.advanced"
        class="fixed cursor-pointer bottom-20 right-4 bg-caspyellow text-black py-2 px-4 rounded-lg border-2 border-black font-bold hidden md:inline-block print:!hidden"
        @click="goToEdit()"
      >
        <i class="fa fa-pen"></i>
      </div>
      <div
        class="fixed print:!hidden cursor-pointer bottom-4 right-4 bg-caspyellow text-black py-2 px-4 rounded-lg border-2 border-black font-bold hidden md:inline-block"
        @click="downloadPDF()"
      >
        <i class="fa fa-download"></i>
      </div>

      <div v-if="type == 'Schichtnachweis'">
        <div class="overflow-auto print:overflow-hidden">
          <table>
            <thead>
              <tr>
                <th>Projekt</th>
                <th>Datum</th>
                <th>Verfasser</th>
                <th>Funktion</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ document.contract }}</td>
                <td>{{ document.date }}</td>
                <td>{{ document.author.name }}</td>
                <td>{{ document.author.function }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <span class="font-bold text-xl">Schichten</span>
        <div class="overflow-auto print:overflow-hidden">
          <table>
            <thead>
              <tr>
                <th>T/N</th>
                <th>Nachname</th>
                <th>Vorname</th>
                <th>Von</th>
                <th>Bis</th>
                <th>Unterschrift</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="job in document.shifts" :key="job.id">
                <td>{{ job.T_N }}</td>
                <td>{{ job.Mitarbeiter_Nachname }}</td>
                <td>{{ job.Mitarbeiter_Vorname }}</td>
                <td>{{ job.Soll_Von }}</td>
                <td>{{ job.Soll_Bis }}</td>
                <td>
                  <div
                    class="w-72 h-12"
                    :class="{
                      'bg-white':
                        stats.type != 'external' &&
                        job.signature.path.length == 0 &&
                        job.Mitarbeiternr == stats.user.persnr,
                    }"
                    @click="showSignature(job.Mitarbeiternr)"
                  >
                    <canvas
                      :ref="job.Mitarbeiternr"
                      class="w-full h-full"
                    ></canvas>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <span class="font-bold text-xl">Material- und Schlüsselübergaben</span>
        <div class="overflow-auto print:overflow-hidden">
          <table>
            <thead>
              <tr class="w-full">
                <th>Typ</th>
                <th class="w-full">Beschreibung</th>
                <th class="min-w-[120px]">Menge</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="pass in document.passes" :key="pass.contextId">
                <td>{{ pass.type }}</td>
                <td>{{ pass.description }}</td>
                <td>{{ pass.amount }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="type == 'Schlüssel- und Materialübergabe'">
        <div class="overflow-auto">
          <table>
            <thead>
              <tr>
                <th>Projekt</th>
                <th>Datum</th>
                <th>Verfasser</th>
                <th>Funktion</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ document.contract }}</td>
                <td>{{ document.date }}</td>
                <td>{{ document.author.name }}</td>
                <td>{{ document.author.function }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <span class="font-bold text-xl">Material- und Schlüsselübergaben</span>
        <div class="overflow-auto">
          <table>
            <thead>
              <tr class="w-full">
                <th>Typ</th>
                <th class="w-full">Beschreibung</th>
                <th class="min-w-[120px]">Menge</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="pass in document.passes" :key="pass.id">
                <td>{{ pass.type }}</td>
                <td>{{ pass.description }}</td>
                <td>{{ pass.amount }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="type == 'Schichtprotokoll'">
        <div class="overflow-auto">
          <table>
            <thead>
              <tr>
                <th>Projekt</th>
                <th>Datum</th>
                <th>Verfasser</th>
                <th>Funktion</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ document.contract }}</td>
                <td>{{ document.date }}</td>
                <td>{{ document.author.name }}</td>
                <td>{{ document.author.function }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <span class="font-bold text-xl">Beobachtungen</span>
        <div class="overflow-auto">
          <table>
            <thead>
              <tr>
                <th>Zeit</th>
                <th>Erkenntnis / Beobachtung</th>
                <th>Ergriffene Maßnahme</th>
                <th>Meldung an</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="event in document.events" :key="event">
                <td>{{ event.time }}</td>
                <td>{{ event.description }}</td>
                <td>{{ event.action }}</td>
                <td>{{ event.reportedTo }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <span class="font-bold text-xl">Technische Defekte</span>
        <div class="overflow-auto">
          <table>
            <thead>
              <tr class="w-full">
                <th class="w-full">Beschreibung</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="defect in document.defects" :key="defect">
                <td>{{ defect.description }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <span class="font-bold text-xl">Besonderheiten / Dienstablauf</span>
        <table>
          <thead>
            <tr class="w-full">
              <th class="w-full">Beschreibung</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ document.misc }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <span class="font-bold text-xl mt-8 inline-block">Unterschriften</span
      ><br />
      <div class="align-top mt-2 inline-block mr-2 text-left">
        <div class="w-72 h-20">
          <canvas ref="passing" class="w-full h-full"></canvas>
        </div>
        <div class="border-t mt-2 border-black inline-block">
          <span>MA Übergabe: {{ document.signatures.passing.id }}</span
          ><br />
          <span v-if="document.signatures.passing.date">{{
            new Date(document.signatures.passing.date).toLocaleString()
          }}</span>
        </div>
      </div>
      <div
        class="align-top mt-2 inline-block mr-2 text-left"
        v-if="document && document.signatures"
      >
        <div
          class="w-72 h-20"
          :class="{
            'bg-white':
              stats.type != 'external' &&
              document.signatures['passed'].path &&
              document.signatures['passed'].path.length == 0 &&
              document.signatures.passed.id == stats.user.persnr,
          }"
          @click="showSignature('passed')"
        >
          <canvas ref="passed" class="w-full h-full"></canvas>
        </div>
        <div class="border-t mt-2 border-black inline-block">
          <span>MA Übernahme: {{ document.signatures.passed.id }}</span
          ><br />
          <span v-if="document.signatures.passed.date">{{
            new Date(document.signatures.passed.date).toLocaleString()
          }}</span>
        </div>
      </div>
      <div class="inline-block break-inside-avoid">
        <div class="align-top mt-2 inline-block mr-2 text-left">
          <div
            class="w-72 h-20"
            :class="{
              'bg-white':
                document.signatures['casp'].path &&
                document.signatures['casp'].path.length == 0 &&
                stats.advanced,
            }"
            @click="showSignature('casp')"
          >
            <canvas ref="casp" class="w-full h-full"></canvas>
          </div>
          <div class="border-t mt-2 border-black inline-block">
            <span>CASP: {{ document.signatures.casp.id }}</span
            ><br />
            <span v-if="document.signatures.casp.date">{{
              new Date(document.signatures.casp.date).toLocaleString()
            }}</span>
          </div>
        </div>
        <div class="align-top mt-2 inline-block mr-2 text-left">
          <div
            class="w-72 h-20"
            :class="{
              'bg-white':
                document.signatures['client'].path &&
                document.signatures['client'].path.length == 0 &&
                stats.type == 'external',
            }"
            @click="showSignature('client')"
          >
            <canvas ref="client" class="w-full h-full"></canvas>
          </div>
          <div class="border-t mt-2 border-black inline-block">
            <span>Kunde: {{ document.signatures.client.id }}</span
            ><br />
            <span v-if="document.signatures.client.date">{{
              new Date(document.signatures.client.date).toLocaleString()
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Toolbar from "@/components/Toolbar.vue";
import CASP from "../../CASP";
import { useCookies } from "vue3-cookies";
import Signature from "../../components/Signature.vue";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  components: { Toolbar, Signature },
  data() {
    return {
      error: "",
      stats: false,
      sign: false,
      currentSignature: false,
      currentPath: [],
      document: false,
      type: "",
    };
  },
  methods: {
    downloadPDF() {
      print();
    },
    goToEdit() {
      window.location =
        "/jobs/" +
        this.$route.params.jobid +
        "/documents/" +
        this.$route.params.doc +
        "/edit";
    },
    documentSigned() {
      location.reload();
    },
    showSignature(type) {
      if (type.startsWith("PSL")) {
        if (
          this.stats.type == "external" ||
          this.stats.user.persnr != type ||
          this.document.shifts.filter((shift) => shift.Mitarbeiternr == type)[0]
            .signature.path.length > 0
        )
          return;
      } else if (this.document.signatures[type].path.length > 0) return;
      else if (type == "client" && this.stats.type != "external") return;
      else if (type == "casp" && !this.stats.advanced) return;
      else if (
        type == "passed" &&
        (this.stats.type == "external" ||
          this.stats.user.persnr != this.document.signatures.passed.id)
      )
        return;

      this.currentSignature = type;
      if (type.startsWith("PSL"))
        this.currentPath = this.document.shifts.filter(
          (shift) => shift.Mitarbeiternr == type
        )[0].path;
      else
        this.currentPath = this.document.signatures[type]
          ? this.document.signatures[type].path
          : [];
      if (!this.currentPath) this.currentPath = [];
      this.sign = true;
      setTimeout(() => {
        this.$refs.sign.draw();
      }, 100);
    },

    getStats() {
      CASP.getStats("", (status, response) => {
        if (status == 200) {
          this.stats = JSON.parse(response);
          this.getJobDocument();
        }
      });
    },
    getJobDocument() {
      let vm = this;
      CASP.getJobDocument(this.$route.params.doc, (status, response) => {
        setTimeout(() => {
          document.getElementById("mainspinner").style.display = "none";
        }, 150);
        if (response.status == "error") {
          vm.error = response.message;
        } else {
          this.type = response.type;
          this.document = response.document;
          this.document.shifts.forEach((shift) => {
            if (!shift.signature) {
              shift.signature = {
                date: null,
                path: [],
              };
            }
          });
          console.log(this.type);
          console.log(this.$refs);
          setTimeout(() => {
            Object.keys(this.document.signatures).forEach((key) => {
              console.log(key);
              vm.$refs.sign.draw(
                vm.$refs[key],
                vm.document.signatures[key].path
              );
            });
            if (this.document.shifts) {
              this.document.shifts.forEach((shift) => {
                console.log("shifts");
                console.log(shift);
                if (!shift.Mitarbeiternr || !vm.$refs[shift.Mitarbeiternr])
                  return;
                vm.$refs.sign.draw(
                  vm.$refs[shift.Mitarbeiternr][0],
                  shift.signature.path
                );
              });
            }
          }, 100);
        }
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.getStats();
    }, 50);
  },
};
</script>
<style>
@media print {
  html,
  body {
    background: #fff;
  }

  #page {
    size: A4;
    margin: 0;
    zoom: 60%;
  }
}
</style>