import { createRouter, createWebHistory } from 'vue-router'
import Jobs from '../views/User/Jobs.vue'
import Archive from '../views/User/Archive.vue'
import Jobdetails from '../views/User/Jobdetails.vue'
import Chats from '../views/User/Chats.vue'
import Tasks from '../views/User/Tasks.vue'
import Chat from '../views/User/Chat.vue'
import Task from '../views/User/Task.vue'
import Dashboard from '../views/Dashboard.vue'
import ID from '../views/User/ID.vue'
import Info from '../views/User/Info.vue'
import Settings from '../views/User/Settings.vue'
import Group from '../views/User/Group.vue'
import Forbidden from '../views/Forbidden.vue'
import Impressum from '../views/User/Impressum.vue'
import Documents from '../views/User/Documents.vue'
import JobDocuments from '../views/User/JobDocuments.vue'
import AddJobDocument from '../views/User/AddJobDocument.vue'
import ViewJobDocument from '../views/User/ViewJobDocument.vue'
import Admincenter from '../views/Admin/Admincenter.vue'
import Login from '../views/Login.vue'
import Adminjobplanning from '../views/Admin/Adminjobplanning.vue'
import Adminusers from '../views/Admin/Adminusers.vue'
import AdminEmployees from '../views/Admin/AdminEmployees.vue'
import Admincheckin from '../views/Admin/Admincheckin.vue'
import AdminCreate from '../views/Admin/AdminCreate.vue'
import AdminMessages from '../views/Admin/AdminMessages.vue'
import ExternalAdmin from '../views/Admin/ExternalAdmin.vue'
import Adminplan from '../views/Admin/Adminplan.vue'
import Adminjobs from '../views/Admin/Adminjobs.vue'
import AdminJobSettings from '../views/Admin/AdminJobSettings.vue'
import AdminRoutes from '../views/Admin/AdminRoutes.vue'
import AdminRoute from '../views/Admin/AdminRoute.vue'
import EWKSList from '../views/User/EWKSList.vue'
import Tour from '../views/User/Tour.vue'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/admin/plan',
    name: 'Adminplan',
    component: Adminplan
  },
  {
    path: '/admin/external',
    name: 'ExternalAdmin',
    component: ExternalAdmin
  },
  {
    path: '/admin/messages',
    name: 'AdminMessages',
    component: AdminMessages
  },
  {
    path: '/admin/jobsettings',
    name: 'AdminJobSettings',
    component: AdminJobSettings
  },
  {
    path: '/admin/routes',
    name: 'AdminRoutes',
    component: AdminRoutes
  },
  {
    path: '/admin/routes/:id',
    name: 'ModifyRoute',
    component: AdminRoute
  },
  {
    path: '/admin/routes/create',
    name: 'CreateRoute',
    component: AdminRoute
  },
  {
    path: '/admin/create',
    name: 'AdminCreate',
    component: AdminCreate
  },{
    path: '/admin/checkin',
    name: 'Admincheckin',
    component: Admincheckin
  },
  {
    path: '/admin/employees',
    name: 'AdminEmployees',
    component: AdminEmployees
  },{
    path: '/admin/adminusers',
    name: 'Adminusers',
    component: Adminusers
  },
  {
    path: '/admin/jobs',
    name: 'Adminjobs',
    component: Adminjobs
  },{
    path: '/admin/planning',
    name: 'Adminjobplanning',
    component: Adminjobplanning
  },
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: Login
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/job/:jobid/tours',
    name: 'EWKSList',
    component: EWKSList
  },
  {
    path: '/admin',
    name: 'Admincenter',
    component: Admincenter
  },
  {
    path: '/documents',
    name: 'Documents',
    component: Documents
  },
  {
    path: '/jobdocuments',
    name: 'JobDocuments',
    component: JobDocuments
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum
  },
  {
    path: '/jobs',
    name: 'Aufträge',
    component: Jobs
  },
  {
    path: '/id',
    name: 'Dienstausweis',
    component: ID
  },
  {
    path: '/info',
    name: 'Persönliche Informationen',
    component: Info
  },
  {
    path: '/settings',
    name: 'Einstellungen',
    component: Settings
  },
  {
    path: '/archive',
    name: 'Archiv',
    component: Archive
  },
  {
    path: '/group/:groupid',
    name: 'Gruppe',
    component: Group
  },

  {
    path: '/jobs/:jobid/routes/:routeid/tours/:index',
    name: 'Tour',
    component: Tour
  },
  {
    path: '/chat',
    name: 'Chats',
    component: Chats
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: Tasks
  },
  {
    path: '/job/:jobid',
    name: 'Jobdetails',
    component: Jobdetails
  },
  {
    path: '/tasks/:id',
    name: 'Task',
    component: Task
  },
  {
    path: '/jobs/:jobid/documents',
    name: 'SpecificJobDocuments',
    component: JobDocuments
  },
  {
    path: '/jobs/:jobid/documents/add',
    name: 'AddJobDocument',
    component: AddJobDocument
  },
  {
    path: '/jobs/:jobid/documents/:doc',
    name: 'ViewJobDocument',
    component: ViewJobDocument
  },
  {
    path: '/jobs/:jobid/documents/:doc/edit',
    name: 'EditJobDocument',
    component: AddJobDocument
  },
  {
    path: '/chat/:chatid',
    name: 'chat',
    component: Chat
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: Forbidden
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
