<template>
  <Toolbar title="Rundgang-Einstellungen" backUrl="/admin"></Toolbar>
  <div
    v-if="jobs != null"
    class="absolute w-full h-full top-0 left-0 bg-black/50 text-center z-40"
  >
    <div class="mt-10 inline-block w-1/2 bg-white rounded-lg p-4 relative">
      <div
        style="position: absolute; right: 40px; color: black"
        class="cursor-pointer"
        @click="jobs = null"
      >
        <i ref="xmark" class="fa fa-xmark text-4xl"></i>
      </div>
      <span class="text-2xl font-bold">Aufträge</span>
      <table class="max-h-96 overflow-y-auto">
        <thead>
          <tr>
            <th>Datum</th>
            <th>Start</th>
            <th>Ende</th>
            <th>Mitarbeiter</th>
            <th>Rundgänge</th>
            <th class="text-center">Aktionen</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="job in jobs" :key="job.id">
            <td>{{ job.Datum_von }}</td>
            <td>{{ job.Soll_Von }}</td>
            <td>{{ job.Soll_Bis }}</td>
            <td>{{ job.user }}</td>
            <td>{{job.finishedTours}}/{{ job.requiredTours}}</td>
            <td><button @click="downloadJob(job)">PDF</button></td>
          </tr>
          <tr v-if="jobs.length == 0" class="col-span-6 text-center">
            <td colspan="6">Keine Rundgänge gefunden</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="w-full text-left p-4">
    <span class="font-bold text-xl">Filtern nach Vertrag</span>
    <div class="mt-2">
      <label class="font-bold mr-3">Objekt-Filter:</label>
      <input
        v-model="filter"
        class="border-b-2 w-64 mr-2 p-2 border-gray-500"
        placeholder="Filter"
      />
      <div
        class="inline-block cursor-pointer pt-2"
        @click="
          filter = '';
          selectedContract = '';
          selectedLocation = '';
          selectedPlace = '';
        "
      >
        <i class="!inline-block fa fa-xmark text-xl align-middle"></i>
      </div>
    </div>
    <div class="relative w-full xl:inline-block">
      <div
        class="inline-block px-3 py-2 rounded-lg shadow-lg m-1 font-bold cursor-pointer bg-white"
        :class="{
          '!bg-caspyellow': selectedContract == contract,
          '!bg-green-200': contracts[contract].routes,
        }"
        v-for="contract in Object.keys(contracts)"
        :key="contract.id"
        @click="selectContract(contract)"
      >
        {{ contract }}
      </div>
    </div>
    <div v-if="selectedContract">
      <span class="text-xl font-bold block"
        >Standorte für {{ selectedContract }}:</span
      >
      <div class="relative w-full xl:inline-block">
        <div
          class="inline-block px-3 py-2 rounded-lg shadow-lg m-1 font-bold cursor-pointer bg-white"
          :class="{
            '!bg-caspyellow': selectedLocation == location,
            '!bg-green-200':
              contracts[selectedContract].locations[location].routes,
          }"
          v-for="location in Object.keys(
            allContracts[selectedContract].locations
          )"
          :key="location.id"
          @click="selectLocation(location)"
        >
          {{ location }}
        </div>
      </div>
      <div v-if="selectedLocation">
        <span class="text-xl font-bold block"
          >Einsatzstellen für {{ selectedLocation }}:</span
        >
        <div class="relative w-full xl:inline-block">
          <div
            class="inline-block px-3 py-2 rounded-lg shadow-lg m-1 font-bold cursor-pointer bg-white"
            :class="{
              '!bg-caspyellow': selectedPlace == place,
              '!bg-green-200':
                allContracts[selectedContract].locations[selectedLocation]
                  .places[place].routes,
            }"
            v-for="place in Object.keys(
              allContracts[selectedContract].locations[selectedLocation].places
            )"
            :key="place.id"
            @click="selectPlace(place)"
          >
            {{ place }}
          </div>
        </div>
        <div v-if="selectedPlace">
          <span class="text-2xl font-bold inline-block mt-4 mr-2">Routen</span
          ><button @click="createRoute()">Erstellen</button>
          <button class="m-1" @click="viewJobsWithTours()">
            Dokumentation anzeigen
          </button>
          <table>
            <thead>
              <th>Name</th>
              <th>Beschreibung</th>
              <th>Status</th>
              <th>Aktionen</th>
            </thead>
            <tbody>
              <tr v-for="route in routes" :key="route.id">
                <td>{{ route.name }}</td>
                <td>{{ route.description }}</td>
                <td>{{ route.active ? "aktiv" : "inaktiv" }}</td>
                <td class="text-center">
                  <button class="m-1" @click="viewRoute(route)">
                    Bearbeiten
                  </button>
                </td>
              </tr>
              <tr v-if="routes.length == 0">
                <td colspan="3" class="text-center">Keine Routen gefunden</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CASP from "@/CASP.js";
import Toolbar from "@/components/Toolbar.vue";
export default {
  name: "AdminDocuments",
  data() {
    return {
      allContracts: {},
      contracts: {},
      filter: "",
      selectedContract: null,
      selectedLocation: null,
      selectedPlace: null,
      selectedPlaceCode: null,
      selectedDate: new Date().toISOString().substr(0, 10),
      routes: [],
      stats: {},
      selectedTab: "Dokumente",
      locationInfo: {},
      jobs: null,
    };
  },
  components: { Toolbar },
  methods: {
    viewRoute(route) {
      window.location = "/admin/routes/" + route.id;
    },
    viewJobsWithTours() {
      this.jobs = [];
      CASP.getJobsWithTours(this.contracts[this.selectedContract].locations[this.selectedLocation]
      .places[this.selectedPlace].code, (status, response) => {
        if (status == 200) {
          this.jobs = response;
        }
      });
    },
    downloadJob(job) {
      window.open(
        process.env.VUE_APP_API_URL + "/routes/jobs/" + job.id + "/download",
        "_blank"
      );
    },
    createRoute() {
      window.location =
        "/admin/routes/create?location=" +
        this.contracts[this.selectedContract].locations[this.selectedLocation]
          .places[this.selectedPlace].code;
    },
    getContracts() {
      CASP.getPresets("", (presets) => {
        this.allContracts = presets.contracts;
        delete this.allContracts[""];
        this.contracts = this.allContracts;
        //this.selectContract(Object.keys(this.allContracts)[0]);
        //this.selectLocation(
        //  Object.keys(this.allContracts[this.selectedContract].locations)[0]
        //);
        //this.selectPlace(
        //  Object.keys(
        //    this.allContracts[this.selectedContract].locations[
        //      this.selectedLocation
        //    ].places
        //  )[0]
        //);
      });
    },
    getStats() {
      CASP.getStats("", (status, response) => {
        if (status == 200) {
          this.stats = JSON.parse(response);
        }
      });
    },
    selectContract(contract) {
      this.filter = contract;
      this.selectedContract = contract;
    },

    selectLocation(location) {
      this.selectedLocation = location;
    },
    selectPlace(place) {
      this.selectedPlace = place;
      this.selectedPlaceCode =
        this.contracts[this.selectedContract].locations[
          this.selectedLocation
        ].places[this.selectedPlace];
      this.getRoutesForPlace();
    },
    getRoutesForPlace() {
      CASP.getRoutesForPlace(this.selectedPlaceCode.code, (status, res) => {
        this.routes = res;
      });
    },
    filterContracts() {
      this.contracts = JSON.parse(JSON.stringify(this.allContracts));
      for (const [key, value] of Object.entries(this.allContracts)) {
        if (!key.toLowerCase().includes(this.filter.toLowerCase())) {
          delete this.contracts[key];
        }
      }
    },
  },
  watch: {
    filter: function () {
      this.filterContracts();
    },
  },
  created: function () {
    this.getStats();
    this.getContracts();
    document.getElementById("mainspinner").style.display = "none";
  },
};
</script>