<template>
  <div id="printtest"></div>
  <div v-show="showDropdown" ref="dropdown" class="z-20 border-gray-500 absolute p-2 bg-white max-h-96 overflow-y-auto" @click="selectDropdown"></div>
  <Transition>
    <div v-if="showActions" ref="modalbg" class="z-30 center w-full h-full lg:px-20 lg:py-10 bg-black/50 text-center">
      <div class="bg-white w-full lg:w-2/3 inline-block lg:rounded-3xl overflow-y-auto lg:px-8 pt-4 basis-0 relative text-center">
        <div class="text-center w-full border-b-4 border-black p-2">
          <div style="position: absolute; right: 40px; color: black" class="cursor-pointer" @click="showActions = false">
            <i ref="xmark" class="fa fa-xmark text-4xl"></i>
          </div>
          <span class="mt-10 font-bold text-3xl text-black">Aktionen</span>
        </div>
        <button v-if="jobs.length > 0" class="inline-block my-2 mx-2  text-white bg-gray-500 border-4 border-gray-500 rounded-lg w-32 py-2 font-bold" @click="exportCSV()">
          CSV Export
        </button>
        <button v-if="jobs.length > 0 && billingMode" class="inline-block my-2 mx-2  text-white bg-gray-500 border-4 border-gray-500 rounded-lg w-32 py-2 font-bold" @click="printPage()">
          Drucken
        </button><br>
        <button v-if="stats.permissions.admin" class="inline-block my-2 mx-2 bg-red-400 border-4 border-gray-500 rounded-lg px-4 py-2 font-bold" @click="requestSMS('confirm')">
          Bestätigung anfordern (SMS)
        </button><br>
        <button v-if="stats.permissions.admin" class="inline-block my-2 mx-2 bg-red-400 border-4 border-gray-500 rounded-lg px-4 py-2 font-bold" @click="requestSMS('accept')">
          Annahme anfordern (SMS)
        </button>
      </div>
    </div>
  </Transition>
  <Transition>
    <TopBanner :mode="bannerMode" :text="bannerText" v-if="bannerText" />
  </Transition>
  <Spinner v-if="loading" />
  <div class="w-full h-full relative text-center m-0 p-0 print:overflow-visible overflow-y-auto pt-3 sm:pt-0 " @mousedown.left="isMouseDown = true" @mouseup="mouseup" @click="generalClick" @keyup.enter="saveSelectedValues()"
    @keyup.esc="generalClick($event, true)">
    <Transition>
      <JobModal v-if="selectedJob" :job="selectedJob" @close="selectedJob = false" :users="allUsers" @changeUser="changeUser" />
    </Transition>
    <Transition>
      <ContactDetails v-if="contactDetails" :token="token" :job="contactDetails" @close="contactDetails = false" />
    </Transition>
    <div class="inline w-full z-10 text-center">
      <img class="w-full lg:mt-24 inline sm:hidden" src="@/assets/banner.jpg" />
      <img class="w-full sm:inline hidden" src="@/assets/DesktopBanner.png" />
    </div>
    <div class="relative w-full h-full text-center pt-5 print:text-xxs origin-top-left">
      <div class="relative w-full text-center 2xl:inline-block">

        <div class="hidden lg:inline absolute left-0">
          <button class=" text-black bg-caspyellow border-4 border-gray-500 rounded-lg w-32 py-2 font-bold" onclick="window.location='/admin'">
            Zurück
          </button>
          <button v-if="jobs.filter(j => j.new).length > 1" class="hidden md:inline-block ml-5  text-black bg-green-300 border-4 border-gray-500 rounded-lg py-2 font-bold" @click="saveAllNewJobs">
            Alle Speichern
          </button>
        </div>

        <div v-if="stats.permissions.admin" class="hidden lg:inline absolute right-0">
        </div>
        <span class="font-bold text-4xl">Auftragsplanung</span> <select class="font-bold text-4xl" v-model="currentMode" @change="getJobs()">
          <option>Messebasis</option>
          <option>Messestw</option>
          <option>Veranstaltung</option>
          <option>Objektschutz</option>
          <option>Global</option>
          <option>App-Ansicht</option>
        </select><br>
        <div class="text-left border-8 border-gray-500 rounded-xl shadow-lg mt-5 p-5 w-11/12 sm:w-5/6 inline-block">
          <span class="block text-2xl font-bold">Filter</span>
          <div class="inline-block p-2 relative">
            <span class="block font-bold mt-2">Projekt</span>
            <div class="absolute right-4 text-2xl bottom-3 text-gray-500 cursor-pointer" @click="filter.project = ''">
              <i class="fa fa-xmark" />
            </div>
            <input size="1" v-model="filter.project" placeholder="Projektname" @click="filterDropdown($event, allContracts.map(el => el.name), { type: 'project' })" class="dropdown border-b-2 w-64 sm:w-96 p-2 border-gray-500"
              @input="filterDropdown($event, allContracts.map(el => el.name), { type: 'project' })" />
          </div>
          <div class="inline-block p-2">
            <div class="inline-block p-2 acwrapper">
              <span class="block font-bold mt-2">Startdatum ab</span>
              <input size="1" type="date" v-model="filter.start" class="border-b-2 w-36 border-gray-500" @change="getJobs" />
            </div>
            <div class="inline-block p-2">
              <span class="block font-bold mt-2">Startdatum bis</span>
              <input size="1" type="date" v-model="filter.end" class="border-b-2 w-36 border-gray-500" @change="getJobs" />
            </div>
          </div>
          <button class="inline-block font-bold mx-1 mt-0 bg-gray-500 rounded p-2 text-white" @click="getJobs()">Suchen</button>
          <button class="inline-block font-bold mx-1 mt-0 bg-gray-500 rounded p-2 text-white" @click="resetFilter()">Filter zurücksetzen</button>
          <div v-if="meta.results == 1000"><span class="text-red-500 font-bold font-xl pt-3">Maximale Anzahl an Ergebnisse erreicht. Bitte Ergebnis durch Filter weiter eingrenzen!</span></div>
          <div class="inline-block align-top px-3">
            <span class="font-bold mt-2">Bedienung</span>
            <span class="block"><b>STRG+Klick</b>: Zeile kopieren</span>
            <span class="block"><b>ALT+Klick</b>: Zeile aus Ansicht löschen (nicht in Navision)</span>
            <span class="block"><b>Doppelklick auf grüne Zeile</b>: Zeile in Navision speichern</span>
            <span class="block"><b>Enter (Markierte Zellen)</b>: Werte speichern</span>
            <span class="block"><b>ESC (Markierte Zellen)</b>: Werte verwerfen</span>
          </div>
        </div>
        <div class="lg:hidden inline-block mt-5">
          <button class=" text-black bg-caspyellow border-4 border-gray-500 rounded-lg w-32 py-2 font-bold" onclick="window.location='/admin'">
            Zurück
          </button>

          <button v-if="!billingMode" class="ml-5 text-white bg-gray-500 border-4 border-gray-500 rounded-lg w-48 py-2 font-bold" @click="billingMode = true">
            Abrechnungsansicht
          </button>
          <button v-else class="ml-5 text-white bg-gray-500 border-4 border-gray-500 rounded-lg w-48 py-2 font-bold" @click="billingMode = false">
            Auftragsübersicht
          </button>
          <button v-if="meta.results > 0" class="mt-3 sm:mt-0  ml-5 text-white bg-gray-500 border-4 border-gray-500 rounded-lg w-28 py-2 font-bold" @click="showActions = true">
            Aktionen
          </button>
        </div>
        <div v-if="searched" class="text-left mt-5">
          <span class="font-bold">Aufträge gefunden: {{ meta.results }}</span><span class="font-bold"> | Summe abrechenbarer Stunden: {{ meta.sumBillableHours }}</span><span class="font-bold"> | Summe Strafen: {{ meta.sumPenalties }}
            €</span>
        </div>
        <div id="tablecontent">
          <table>
            <thead ref="head">
              <th>Aktionen</th>
              <th class="min-w[100px]" v-for="column in modes[currentMode]" :key="column.name">{{ column.name }}</th>
            </thead>
            <tbody ref="body">
              <tr id="filterrow" class="border-b-2 border-black print:hidden">
                <td></td>
                <td v-for="column in modes[currentMode]" :key="column.name">
                  <div v-if="!column.noFilter">
                    <select v-if="column.type == 'checkbox'" @change="getJobs" v-model="filter[column.column]">
                      <option value="">Auswählen</option>
                      <option value="true">Ja</option>
                      <option value="false">Nein</option>
                    </select>
                    <input v-else size="1" class="min-w-0 w-full" v-model="filter[column.column]" @change="getJobs()" :placeholder="column.name" />
                  </div>

                </td>
              </tr>
              <tr v-for="job in jobs" :key="job.id" @click.ctrl="duplicateJob(job)" @click.alt="dismissJob(job)" @dblclick="createJob(job)" v-bind:class="{ '!bg-green-200': job.new }">
                <td><button @click="duplicateJob(job)">K</button><button class="ml-1" v-if="job.new" @click="dismissJob(job)">L</button></td>
                <td v-for="column in modes[currentMode]" :key="column.name">
                  <span v-if="column.readonly || (column.column == 'Einsatzort_Beschreibung' && job.Vertragsbeschreibung == '') || (column.column == 'Einsatzstelle_Beschreibung' && job.Einsatzort_Beschreibung == '')">{{ job[column.column]
                    }}</span>

                  <input @mouseenter="hover($event, job, column.column)" @mouseleave="hover($event, job, column.column)" v-else-if="column.column == 'Vertragsbeschreibung'" v-model="job[column.column]" size="1" :placeholder="column.name"
                    @click="filterDropdown($event, findContractNames(), { type: column.column, job: job })" @input="filterDropdown($event, findContractNames(), { type: column.column, job: job })"
                    v-bind:class="{ 'marked': selectedJobs.includes(job) && selectedColumn == column.column }" class="dropdown border-b-2 w-64 sm:w-96  border-gray-500" />

                  <input @mouseenter="hover($event, job, column.column)" @mouseleave="hover($event, job, column.column)" v-else-if="column.column == 'Einsatzort_Beschreibung'" v-model="job[column.column]" size="1" :placeholder="column.name"
                    @click="filterDropdown($event, findLocationNamesForJob(job), { type: column.column, job: job })"
                    @input="filterDropdown($event, findLocationNamesForJob(job), { type: column.column, job: job })" v-bind:class="{ 'marked': selectedJobs.includes(job) && selectedColumn == column.column }"
                    class="dropdown border-b-2 w-64 sm:w-96  border-gray-500" />
                  <input @mouseenter="hover($event, job, column.column)" @mouseleave="hover($event, job, column.column)" v-else-if="column.column == 'Einsatzstelle_Beschreibung'" v-model="job[column.column]" size="1"
                    :placeholder="column.name" @click="filterDropdown($event, findPlaceNamesForJob(job), { type: column.column, job: job })"
                    @input="filterDropdown($event, findPlaceNamesForJob(job), { type: column.column, job: job })"
                    v-bind:class="{ 'marked': selectedJobs.includes(job) && selectedColumn == column.column }" class="dropdown border-b-2 w-64 sm:w-96  border-gray-500" />
                  <input @mouseenter="hover($event, job, column.column)" @mouseleave="hover($event, job, column.column)" v-else-if="column.column == 'Firmenkurzel'" v-model="job[column.column]" size="1" :placeholder="column.name"
                    @click="filterDropdown($event, allCompanies, { type: column.column, job: job })" @input="filterDropdown($event, allCompanies, { type: column.column, job: job })"
                    v-bind:class="{ 'marked': selectedJobs.includes(job) && selectedColumn == column.column }" class="dropdown border-b-2  border-gray-500" />
                  <input @mouseenter="hover($event, job, column.column)" @mouseleave="hover($event, job, column.column)" v-else-if="column.column == 'Leistungsart_Code'" v-model="job[column.column]" size="1" :placeholder="column.name"
                    @click="filterDropdown($event, allServicetypes, { type: column.column, job: job })" @input="filterDropdown($event, allServicetypes, { type: column.column, job: job })"
                    v-bind:class="{ 'marked': selectedJobs.includes(job) && selectedColumn == column.column }" class="dropdown border-b-2  border-gray-500" />
                  <input @mouseenter="hover($event, job, column.column)" @mouseleave="hover($event, job, column.column)" v-else-if="column.column == 'Mitarbeiternr'" v-model="job[column.column]" size="1" :placeholder="column.name"
                    @click="filterDropdown($event, allEmployees, { type: column.column, job: job })" @input="filterDropdown($event, allEmployees, { type: column.column, job: job })"
                    v-bind:class="{ 'marked': selectedJobs.includes(job) && selectedColumn == column.column }" class="dropdown border-b-2  border-gray-500" />
                  <div v-else-if="column.type == 'checkbox'">
                    <input @mouseenter="hover($event, job, column.column)" @mouseleave="hover($event, job, column.column)" :type="column.type" v-model="job[column.column]" size="1" class="min-w-0 w-full" :placeholder="column.name"
                      v-bind:class="{ 'marked': selectedJobs.includes(job) && selectedColumn == column.column }" @change="change($event)" @click="inputClick($event, job, column.column)" :id="job.id + column.column" />
                    <label :for="job.id + column.column" class="inline-block"></label>
                  </div>
                  <div v-else-if="column.column == 'Lfd_Nr'">
                    <input :type="column.type" v-model="job[column.column]" size="1" class="min-w-0 w-full" :placeholder="column.name" @change="onChangedInput(job, column.column)" />
                    <button class="block" v-if="job == jobs[0]" @click="updateLfd(job)">Zählen</button>
                  </div>
                  <input v-else @change="onChangedInput(job, column.column)" @mouseenter="hover($event, job, column.column)" @mouseleave="hover($event, job, column.column)" :type="column.type" v-model="job[column.column]" size="1"
                    class="min-w-0 w-full" :placeholder="column.name" v-bind:class="{ 'marked': selectedJobs.includes(job) && selectedColumn == column.column }" @input="change($event)" @click="inputClick" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <button @click="jobs.push({ new: true, Zeiterf_Buch_Blattname: currentMode.toUpperCase() })">Job hinzufügen</button>
      </div>
    </div>
  </div>
</template>

<script>
import CASP from "../../CASP";
import { useCookies } from "vue3-cookies";
import JobModal from "@/components/JobModal.vue";
import ContactDetails from "@/components/ContactDetails.vue";
import Spinner from "@/components/Spinner";
import TopBanner from "@/components/TopBanner";
import views from "@/lib/views.json";
let in2Days = new Date()
in2Days.setDate(in2Days.getDate() + 2)
let aweekago = new Date()
aweekago.setDate(aweekago.getDate() - 7)
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  name: "Jobs",
  components: { ContactDetails, JobModal, Spinner, TopBanner },
  data() {
    return {
      token: "",
      currentMode: "Messebasis",
      modes: views,
      filter: {
        project: "",
        start: aweekago.toISOString().split("T")[0],
        end: in2Days.toISOString().split("T")[0]
      },
      printing: false,
      loading: false,
      bannerText: "",
      bannerMode: "success",
      allProjects: [],
      allCompanies: [],
      allServicetypes: [],
      allContracts: {},
      allEmployees: {},
      contactDetails: false,
      showDropdown: false,
      showActions: false,
      jobEmployeeDropdown: -1,
      selectedJob: false,
      searched: false,
      dropdownRef: {},
      dropdownInitial: "",
      jobs: [],
      contracts: [],
      billingMode: false,
      meta: {
        results: 0,
        sumBillableHours: 0,
        sumPenalties: 0,
      },
      stats: { permissions: {} },
      isMouseDown: false,
      selectedJobs: [],
      selectedInputs: [],
      selectedColumn: "",
      defaultValues: [],
      justCreated: false,
      newId: 0
    };
  },
  methods: {
    async updateLfd(job) {
      let firstNumber = parseInt(job.Lfd_Nr)
      if (firstNumber) {
        for (let i = 0; i < this.jobs.length; i++) {
          this.jobs[i].Lfd_Nr = firstNumber + i
          if ((firstNumber + i).toString().length == 1) {
            this.jobs[i].Lfd_Nr = "00" + (firstNumber + i)
          } else if ((firstNumber + i).toString().length == 2) {
            this.jobs[i].Lfd_Nr = "0" + (firstNumber + i)
          }
          this.changeJob(this.jobs[i], "Lfd_Nr")
          //await this.changeJobSync(this.jobs[i], "Lfd_Nr")
        }
      }

    },
    findContractNames(){
      return this.allContracts.map(c=>c.name)
    },
    findLocationNamesForJob(job){
      return this.allContracts.find(c=>c.name == job.Vertragsbeschreibung)?.locations.map(l=>l.name)
    },
    findPlaceNamesForJob(job){
      return this.allContracts.find(c=>c.name == job.Vertragsbeschreibung)?.locations.find(l=>l.name == job.Einsatzort_Beschreibung)?.places.map(p=>p.name)
    },
    duplicateJob(job) {
      if (["App-Ansicht"].includes(this.currentMode)) {
        this.bannerText = "In dieser Ansicht können keine Aufträge erstellt werden"
        this.bannerMode = "error"
        setTimeout(() => {
          this.bannerText = ""
        }, 2000)
        return

      }
      let dup = JSON.parse(JSON.stringify(job))
      dup.Mitarbeiternr = ""
      dup.Mitarbeitername = ""
      dup.Mitarbeiter_Vorname = ""
      dup.Mitarbeiter_Nachname = ""
      dup.Von_Uhrzeit = ""
      dup.Bis_Uhrzeit = ""
      dup.Ist_Zeiten_geprüft = false
      dup.Bez = false
      dup.VA_Bewachung = false
      dup.VA_Normal = false
      dup.VA_Top = false
      dup.MA_Info = false
      dup.KD_Info = false
      dup.MA_gebucht = false
      dup["MA_bestätigt"] = false
      dup.Mitarbeiter_Nachname = ""
      dup.Lfd_Nr = ""
      dup.Dispo = true
      dup.new = true
      dup.id = "new" + this.newId
      delete dup.Einsatznr
      delete dup.Einsatzzeitnr
      delete dup.Soll_Stunden
      this.newId++
      this.jobs.splice(this.jobs.indexOf(job) + 1, 0, dup)
    },
    dismissJob(job) {
      this.jobs.splice(this.jobs.indexOf(job), 1)
    },
    clearSelectedJobs() {
      for (let i = 0; i < this.selectedJobs.length; i++) {
        this.selectedJobs[i][this.selectedColumn] = this.defaultValues[i]
      }
      this.selectedJobs = []
    },

    mouseup() {
      setTimeout(() => {
        this.isMouseDown = false
        if (this.selectedJobs.length > 0) {
          let firstInput = this.selectedInputs[0]
          firstInput.click()
          firstInput.select()
          if (firstInput.type == "time") firstInput.showPicker()
          if (firstInput.type == "date") firstInput.showPicker()
        }
      }, 100)

    },
    change(event) {
      if (this.selectedJobs.length > 0) {
        this.selectedJobs.forEach((job) => {
          job[this.selectedColumn] = event.target.value
        })
      }
    },
    hover(event, job, column) {
      if (this.isMouseDown) {
        if (this.selectedJobs.length == 0) {
          this.selectedColumn = column
          this.defaultValues = []
          this.selectedInputs = []
          this.justCreated = true
        } else if (this.selectedColumn != column) {
          return;
        }
        if (!this.selectedJobs.includes(job)) {
          this.selectedJobs.push(job)
          this.defaultValues.push(job[column])
          this.selectedInputs.push(event.target)
        }
      }
    },
    inputClick(event, job = false, column = false) {
      event.target.style.width = event.target.scrollWidth + "px"
      if (job) { //when a checkbox is clicked
        job[column] = event.target.checked
        this.changeJob(job, column)
      }
    },
    resetFilter() {
      this.filter = {
        project: "",
        start: new Date().toISOString().split("T")[0],
        end: in2Days.toISOString().split("T")[0],
        location: "",
        place: "",
        position: "",
        persnr: "",
        accepted: "",
        confirmed: "",
        time_checked: "",
        Bez: "",
        checkinRequired: ""
      }
      this.getJobs()
    },
    filterDropdown(event, data, reference) {
      this.change(event)
      console.log(event)
      this.dropdownRef = reference;
      let options = "<p class='dp'>-><-</p>";
      let empty = true
      data.forEach((el) => {
        if (el.toLowerCase().includes(event.target.value.toLowerCase())) {
          empty = false;
          options += "<p class='dp'>" + el + "</p>";
        }
      });
      if (this.$refs.dropdown) {
        if (!this.showDropdown) {
          this.dropdownInitial = event.target.value
        }
        this.$refs.dropdown.innerHTML = options;
        if (!empty) {
          setTimeout(() => {
            this.showDropdown = true;
            setTimeout(() => {
              event.target.style.width = this.$refs.dropdown.scrollWidth + "px"
            }, 50)
          }, 50)
        }
        event.target.parentNode.appendChild(this.$refs.dropdown)
      }
    },
    selectDropdown(event) {
      console.log(this.dropdownRef)
      if (this.dropdownRef.type == "project") {
        this.filter.project = event.target.innerText
        this.getJobs()
      } else if (this.dropdownRef.job) {
        if (this.selectedJobs.length == 0) {
          this.selectedJobs = [this.dropdownRef.job]
        }
        this.selectedJobs.forEach((job) => {
          if (event.target.innerText == "-><-") job[this.dropdownRef.type] = ""
          else job[this.dropdownRef.type] = event.target.innerText
          if (this.dropdownRef.type == "Vertragsbeschreibung") {
            this.setContract(job, event.target.innerText)
          } else if (this.dropdownRef.type == "Einsatzort_Beschreibung") {
            this.setLocation(job, event.target.innerText)
          } else if (this.dropdownRef.type == "Einsatzstelle_Beschreibung") {
            this.setPlace(job, event.target.innerText)
          } else if (this.dropdownRef.type == "Mitarbeiternr") {
            this.changeUser(job)
          } else {
            this.changeJob(job, this.dropdownRef.type)
          }
        })
      }
      this.dropdownRef.job = null
      this.selectedJobs = []
    },
    async saveSelectedValues(event) {
      console.log("save" + this.selectedJobs.length + " " + this.selectedColumn)
      if (this.selectedJobs.length > 0) {
        for (let i = 0; i < this.selectedJobs.length; i++) {
          this.onChangedInput(this.selectedJobs[i], this.selectedColumn, true)
        }
      }
      this.selectedJobs = []
    },
    setContract(job, contract) {
      let contractData= this.allContracts.find(c => c.name == contract)
      job.Vertragsbeschreibung = contractData.name
      job.Belegnr = contractData.code
      if (contractData.locations.length == 1) {
        this.setLocation(job, contractData.locations[0].name)
      } else {
        this.changeMultipleAttributes(job, {
          Belegnr: job.Belegnr,
          Einsatzort_Code: "",
          Einsatzstelle_Code: ""
        })
        job.Einsatzort_Beschreibung = ""
        job.Einsatzort_Code = ""
        job.Einsatzstelle_Beschreibung = ""
        job.Einsatzstelle_Code = ""
      }
    },
    setLocation(job, location) {
      let locationData = this.allContracts.find(c => c.code == job.Belegnr).locations.find(l => l.name == location)
      job.Einsatzort_Beschreibung = locationData.name
      job.Einsatzort_Code = locationData.code
      
      if (locationData.places.length == 1) {
        this.setPlace(job, locationData.places[0].name)
      } else {
        this.changeMultipleAttributes(job, {
          Belegnr: job.Belegnr,
          Einsatzort_Code: job.Einsatzort_Code,
          Einsatzstelle_Code: ""
        })
        job.Einsatzstelle_Beschreibung = ""
        job.Einsatzstelle_Code = ""
      }

    },
    setPlace(job, place) {
      let placeData=this.allContracts.find(c => c.code == job.Belegnr).locations.find(l => l.code == job.Einsatzort_Code).places.find(p => p.name == place)
      job.Einsatzstelle_Beschreibung = placeData.name
      job.Einsatzstelle_Code = placeData.code
      this.changeMultipleAttributes(job, {
        Belegnr: job.Belegnr,
        Einsatzort_Code: job.Einsatzort_Code,
        Einsatzstelle_Code: job.Einsatzstelle_Code
      })
    },
    generalClick(event, close = false) {
      if (!this.justCreated && (close || (event.target.className.includes && !event.target.className.includes("dropdown") && !this.selectedInputs.includes(event.target)))) {
        this.showDropdown = false
        this.clearSelectedJobs()
        if (this.dropdownRef.job) {
          this.dropdownRef.job[this.dropdownRef.type] = this.dropdownInitial
        }
      }
      if (this.justCreated) this.justCreated = false
    },
    exportCSV() {
      let text = ""
      let fields = []
      if (!this.billingMode) {
        text = "Lfd Nr.;Datum;Beginn;Ende;Einsatzort;Einsatzstelle;Position;Mitarbeiter;Angenommen;Bestätigt;Check-In notwendig?"
        fields = [
          "Lfd_Nr",
          "datum_formatted",
          "Soll_Von",
          "Soll_Bis",
          "Einsatzort_Beschreibung",
          "Einsatzstelle_Beschreibung",
          "Pos",
          "Mitarbeiternr",
          "MA_gebucht",
          "MA_bestätigt",
          "checkinRequired"
        ]
      } else {
        text = "Datum;Beginn;Ende;Stunden;Strafen;Einsatzort;Einsatzstelle;Position;Firma;Mitarbeiter;Angenommen;Bestätigt;Zeit geprüft;Bezahlt"
        fields = [
          "datum_formatted",
          "Soll_Von",
          "Soll_Bis",
          "hours",
          "Q",
          "Einsatzort_Beschreibung",
          "Einsatzstelle_Beschreibung",
          "Pos",
          "Firmenkurzel",
          "Mitarbeiternr",
          "MA_gebucht",
          "MA_bestätigt",
          "Ist_Zeiten_geprüft",
          "Bez"]
      }
      this.jobs.forEach(job => {
        text += "\n"
        job.hours = (job.hours + "").replace(".", ",")
        fields.forEach(field => {
          if (job["Ist_Zeiten_geprüft"] && field == "Soll_Von") field = "Von_Uhrzeit"
          if (job["Ist_Zeiten_geprüft"] && field == "Soll_Bis") field = "Bis_Uhrzeit"
          text += job[field] === true ? "X" : job[field] === false ? "" : job[field]
          text += ";"
        })
      })
      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', new Date().toJSON().split("T")[0] + "_auftraege.csv");
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },

    changeUser(job) {
      this.selectedJob = false
      this.changeMultipleAttributes(job, { Mitarbeiternr: job.Mitarbeiternr.split(" (")[0] });
    },
    getPresets() {
      let vm = this
      CASP.getPresets(this.token, (res) => {
        if (res) {
          vm.allCompanies = res.companies
          vm.allServicetypes = res.Leistungsart_Codes
          vm.allContracts = res.contracts
          vm.allEmployees = []
          Object.keys(res.employees).forEach((el) => {
            vm.allEmployees.push(el + " (" + res.employees[el].name + " " + res.employees[el].surname + ")")
          })
        }
      })
    },
    onChangedInput(job, column, calledFromMultisave = false) {
      if (!calledFromMultisave && this.selectedJobs.length > 1) {
        return
      } else {
        if ((column == "T_N" && job.Datum_von) || (column == "Datum_von" && job.T_N)) {
          job.T_N = job.T_N.toUpperCase();
          if (job.T_N == "N") {
            let date = new Date(job.Datum_von)
            date.setDate(date.getDate() + 1)
            job.Datum_bis = date.toISOString().split("T")[0]
          } else {
            job.Datum_bis = job.Datum_von
          }
          this.changeMultipleAttributes(job, {
            T_N: job.T_N,
            Datum_von: job.Datum_von,
            Datum_bis: job.Datum_bis
          })
        }
        else {
          this.changeJob(job, column)
        }
      }
    },
    changeJob(job, column) {
      if (job.new) return;
      let vm = this
      CASP.changeJob(this.token, job.id, { [column]: job[column] }, (res) => {
        if (res.error) {
          vm.bannerText = res.error
          vm.bannerMode = "error"
          setTimeout(() => {
            vm.bannerText = ""
          }, 5000)
        }

      })
    },
    changeMultipleAttributes(job, changes) {
      if (job.new) return;
      let vm = this
      CASP.changeJob(this.token, job.id, changes, (res) => {
        if (res.error) {
          vm.bannerText = res.error
          vm.bannerMode = "error"
          setTimeout(() => {
            vm.bannerText = ""
          }, 5000)
        }
      })
    },
    async saveAllNewJobs() {
      let newJobs = this.jobs.filter(job => job.new)
      for (let i = 0; i < newJobs.length; i++) {
        await this.createJob(newJobs[i])
      }
    },
    createJob(job) {
      return new Promise((resolve, reject) => {
        if (job.new) {
          let vm = this;
          CASP.createJob(this.token, job, (res) => {
            if (res.error) {
              vm.bannerText = res.error
              vm.bannerMode = "error"
              setTimeout(() => {
                vm.bannerText = ""
                reject()
              }, 5000)
            } else {
              vm.jobs[vm.jobs.indexOf(job)] = res
              resolve()
            }
          })
        }
      })
    },
    getUsers() {
      let vm = this;
      CASP.getUsers(this.token, (res) => {
        if (res) {
          vm.allUsers = res;
        }
      });
    },
    filterUsers(job) {
      if (job.id == 0) job.Mitarbeiternr = this.filter.persnr
      console.log("filter" + job.id);
      this.jobEmployeeDropdown = job.id;
      let vm = this
      let options = "";
      if (job.id == 0) {
        options = "<p class='dp'>Offen</p>";
      }
      this.allUsers.forEach((el) => {
        console.log(el.displayName.toLowerCase().includes(this.filter.persnr.toLowerCase()))
        console.log(vm.filter.persnr.toLowerCase())
        console.log(el.displayName.toLowerCase())
        if (
          this.jobEmployeeDropdown != 0 && el.displayName.toLowerCase().includes(job.Mitarbeiternr.toLowerCase())
        ) {
          options += "<p class='dp'>" + el.displayName + "</p>";
        } else if (
          this.jobEmployeeDropdown == 0 && el.displayName.toLowerCase().includes(this.filter.persnr.toLowerCase())
        ) {
          options += "<p class='dp'>" + el.displayName + "</p>";
        }
      });
      //console.log(options)
      if (this.$refs["jobDropdown" + job.id]) {
        console.log("setting")
        console.log("jobDropdown" + job.id)
        if (this.$refs["jobDropdown" + job.id][0]) this.$refs["jobDropdown" + job.id][0].innerHTML = options;
        else this.$refs["jobDropdown" + job.id].innerHTML = options;
        console.log(this.$refs["jobDropdown" + job.id])
      }

    },
    printPage() {
      this.showActions = false
      setTimeout(() => { print() }, 500)

    },
    getJobs() {
      this.loading = true;
      let vm = this;
      this.filter.Zeiterf_Buch_Blattname = this.currentMode.toUpperCase()
      if (["Global", "App-Ansicht"].includes(this.currentMode)) {
        delete this.filter.Zeiterf_Buch_Blattname
      }
      if (this.connection) {
        this.connection.send(JSON.stringify({ type: "init", token: this.token, filter: this.filter }))
      }
      this.jobs = []
      CASP.getAdminJobs(
        this.token,
        this.filter,
        (res) => {
          vm.meta = res.meta
          vm.jobs = res.jobs;
          vm.searched = true;
          vm.loading = false;
        }
      );
    },
    startSocket() {
      const vm = this
      this.connection = new WebSocket(process.env.VUE_APP_WS_URL + '/updates')
      this.connection.onopen = function (event) {
        setTimeout(() => {
          vm.connection.send(JSON.stringify({ type: "init", token: vm.token, filter: vm.filter }))
        }, 500)

      }
      this.connection.onmessage = function (event) {
        let data = JSON.parse(event.data)
        if (data.type === "checkin") {
          vm.showCheckin(data.job)
          let newJobs = []
          vm.jobs.forEach((job) => {
            if (job.id == data.job.id) {
              newJobs.push(data.job)
            } else {
              newJobs.push(job)
            }
          })
          vm.jobs = newJobs;
        } else if (data.type == "update") {
          for (let i = 0; i < vm.jobs.length; i++) {
            if (vm.jobs[i].id == data.job.id) {
              vm.jobs[i] = data.job
              vm.jobs[i].new = true
              setTimeout(() => {
                vm.jobs[i].new = false
              }, 1000)
            }
          }
        }
      }
    },
  },
  computed: {
    today: function () {
      let d = new Date().toISOString().split("T")[0].split("-");
      return d[2] + "." + d[1] + "." + d[0];
    },
    datestamp: function () {
      return new Date().toISOString().split("T")[0] + "00:00:00";
    },
  },
  mounted: function () {
    let vm = this
    window.vm=vm
    this.modes[this.currentMode].forEach((el) => {
      this.filter[el.column] = "";
    })
    document.getElementById("mainspinner").style.display = "none";
    if (this.cookies.get("authtoken")) {
      this.token = this.cookies.get("authtoken");
      CASP.isAuthenticated(this.token, (auth) => {
        if (!auth) {
          window.location = "/admin/login";
        } else {
          this.getPresets();
          this.getUsers();
          this.getJobs();
          this.startSocket();
          CASP.getStats(this.token, (status, response) => {
            if (status == 200) {
              vm.stats = JSON.parse(response)
            }
          })
        }
      });
    } else {
      window.location = "/admin/login";
    }
  },
};
</script>
<style scoped>
.marked {
  background: yellowgreen;
}

#filterrow td {
  border-bottom: 2px solid black;
}

tr {
  page-break-inside: avoid;
}

@region {
  size: auto;
}

@region table {
  break-before: avoid;
}

table {
  page-break-before: auto;
  /* or always, avoid */
  page-break-after: auto;
  /* or always, avoid */
}

td {
  white-space: nowrap;
}

@media print {
  #printtest {
    display: none
  }

  button {
    display: none
  }

  td,
  th {
    padding: 2px;
    margin: 2px;
    max-width: 75px;
    white-space: normal;
    word-break: break-word;

  }
}

th {
  padding: 4px;
  margin: 2px;
  min-width: 75px;
  white-space: nowrap;
  word-break: break-word;
}
</style>